import { template as template_385c087967d641be9a85d6192633a41a } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_385c087967d641be9a85d6192633a41a(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
