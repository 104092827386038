import { template as template_7281ef9102b74d6687b5f22a0910a8e5 } from "@ember/template-compiler";
const FKLabel = template_7281ef9102b74d6687b5f22a0910a8e5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
