import { template as template_6bc00dfe56314e758f24aaa0e33e5594 } from "@ember/template-compiler";
const SidebarSectionMessage = template_6bc00dfe56314e758f24aaa0e33e5594(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
