import { template as template_20bd38e4a708425885ce8af9d03a40f0 } from "@ember/template-compiler";
import dirSpan from "discourse/helpers/dir-span";
import { i18n } from "discourse-i18n";
const TopicExcerpt = template_20bd38e4a708425885ce8af9d03a40f0(`
  {{#if @topic.hasExcerpt}}
    <a href={{@topic.url}} class="topic-excerpt">
      {{dirSpan @topic.escapedExcerpt htmlSafe="true"}}

      {{#if @topic.excerptTruncated}}
        <span class="topic-excerpt-more">{{i18n "read_more"}}</span>
      {{/if}}
    </a>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TopicExcerpt;
