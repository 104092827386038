import { template as template_76baf0ca957c49a5bee3c6d005438554 } from "@ember/template-compiler";
const FKText = template_76baf0ca957c49a5bee3c6d005438554(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
