import { template as template_00eb8140f90d489b875dda724c7459d3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_00eb8140f90d489b875dda724c7459d3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
